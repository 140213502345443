<template>
  <div
    class="circle-icon"
    :class="{
      'circle-icon--alert': alert,
      'circle-icon--pinned': pinned,
      'circle-icon--lg': lg,
      'circle-icon--xl': xl,
      'circle-icon--green': color === 'green',
      'circle-icon--blue': color === 'blue',
      'circle-icon--red': color === 'red',
    }"
  >
    <BaseSVG :src="require(`@/assets/${icon}-icon.svg`)" />
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";

export default {
  name: "CircleIcon",
  props: {
    icon: {
      type: String,
      required: true,
      default: "check-mark",
    },
    alert: {
      type: Boolean,
      required: false,
      default: false,
    },
    pinned: {
      type: Boolean,
      required: false,
      default: false,
    },
    lg: {
      type: Boolean,
      required: false,
      default: false,
    },
    xl: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: "green",
    },
  },
  components: {
    BaseSVG,
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.circle-icon {
  width: 32px;
  height: 32px;
  background: $fade-green;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    width: 14px;
    height: auto;
    fill: $green;

    path {
      fill: $green;
    }
  }

  &--green {
    background: $fade-green;

    svg {
      fill: $green;

      path {
        fill: $green;
      }
    }
  }

  &--blue {
    background: $fade-blue;

    svg {
      fill: $light-blue-tint0;

      path {
        fill: $light-blue-tint0;
      }
    }
  }

  &--red,
  &--alert {
    background: $fade-red;

    svg {
      fill: $red;

      path {
        fill: $red;
      }
    }
  }

  &--pinned {
    top: 50%;
    margin-top: -16px;
    left: 0;
    position: absolute;
  }

  &--lg {
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
    }

    &.circle-icon--pinned {
      margin-top: -20px;
    }
  }

  &--xl {
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
    }

    &.circle-icon--pinned {
      margin-top: -24px;
    }
  }
}
</style>
