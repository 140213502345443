<template>
  <div class="card action-card">
    <header class="action-card__header">
      <div class="action-card__header-content">
        <h3 class="action-card__heading">{{ heading | truncate(30) }}</h3>
        <p v-if="subheading" class="action-card__subheading">
          {{ subheading | truncate(30) }}
        </p>
      </div>
      <CircleIcon :lg="true" :icon="icon" v-if="icon" :color="color" />
    </header>

    <div class="action-card__content">
      <slot />
    </div>

    <footer class="action-card__footer">
      <div
        class="action-card__footer-cell"
        v-for="(action, index) in actions"
        :key="`action-${index}`"
      >
        <button class="action-card__footer-button" @click="action.onClick">
          <BaseSVG
            :src="require(`@/assets/${action.icon}-icon.svg`)"
            v-if="action.icon"
          />
          <span>{{ action.label }}</span>
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import CircleIcon from "@/components/CircleIcon";
import BaseSVG from "@/components/base/BaseSVG";

export default {
  name: "ActionCard",
  props: {
    heading: {
      type: String,
      required: true,
      default: "",
    },
    subheading: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    color: {
      type: String,
      required: false,
    },
  },
  components: {
    CircleIcon,
    BaseSVG,
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.action-card {
  position: relative;
  padding-bottom: calc(2rem + 64px) !important;

  &__heading {
    font-size: 1rem;
  }
  &__heading + .pill {
    margin-top: 0.5rem;
  }

  &__subheading {
    font-size: 0.8625rem;
    color: $medium-gray;
    margin-top: 0.25em;
    text-transform: capitalize;
  }

  &__header {
    display: flex;
    align-items: center;

    &-content {
      max-width: calc(100% - 64px);
    }

    .circle-icon {
      margin-left: auto;
    }
  }

  &__content {
    padding: 1rem 0 0 0;
  }

  &__footer {
    border-top: solid 2px $fade-gray;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 64px;
    display: flex;

    &-cell {
      border-right: solid 2px $fade-gray;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 0.875rem;

      svg {
        width: 18px;
        height: 18px;
        fill: $green;
        margin-right: 0.5rem;
        margin-top: -4px;
      }

      &:last-of-type {
        border-right: 0;
      }
    }

    &-button {
      font-family: inherit;
      border: none;
      background: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      display: inline-flex;
      align-items: center;
      color: $gray;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>