import heroIcon from "@/assets/hero-icon.svg";
import leadInIcon from "@/assets/lead-in-icon.svg";
import leftBlockIcon from "@/assets/left-block-icon.svg";
import rightBlockIcon from "@/assets/right-block-icon.svg";
import spotlightIcon from "@/assets/spotlight-icon.svg";
import longFormIcon from "@/assets/long-form-icon.svg";
import testimonialIcon from "@/assets/testimonial-icon.svg";
import collectionIcon from "@/assets/collection-icon.svg";
import dataVisualizationIcon from "@/assets/data-visualization-icon.svg";
import cardGroupIcon from "@/assets/card-group-icon.svg";
import schedulerIcon from "@/assets/scheduler-icon.svg";
import videoIcon from "@/assets/video-icon.svg";
import formHeroIcon from "@/assets/form-hero-icon.svg";
import gateIcon from "@/assets/gate-icon.svg";
import listIcon from "@/assets/list-icon.svg";
import infographicIcon from "@/assets/infographic-icon.svg";
import oncologyIcon from "@/assets/oncology-icon.svg";
import cardiologyIcon from "@/assets/cardiology-icon.svg";
import orthopedicsIcon from "@/assets/orthopedics-icon.svg";
import voca from "voca";

export const content = {
  data() {
    return {
      cardLayouts: [
        {
          value: "slider",
          label: "Slider",
        },
        {
          value: "tile",
          label: "Tiles",
        },
        {
          value: "infographic",
          label: "Infographic",
        },
      ],
      infographicLayouts: [
        {
          value: "icons",
          label: "Icons",
        },
        {
          value: "quotes",
          label: "Quotes",
        },
      ],
      collectionLayouts: [
        {
          value: "tile",
          label: "Tile",
        },
        {
          value: "list",
          label: "List",
        },
      ],
      heroLayouts: [
        {
          value: "simple",
          label: "Simple",
        },
        {
          value: "detailed",
          label: "Detailed",
        },
      ],
      leadInLayouts: [
        {
          value: "simple",
          label: "Simple",
        },
        {
          value: "inline",
          label: "Inline",
        },
      ],
      listLayouts: [
        {
          value: "default",
          label: "Default",
        },
        {
          value: "block",
          label: "Block",
        },
        {
          value: "accordion",
          label: "Accordion",
        },
      ],
      spotlightLayouts: [
        {
          value: "default",
          label: "Default",
        },
        {
          value: "simple",
          label: "Simple",
        },
        {
          value: "detailed",
          label: "Detailed",
        },
      ],
      testimonialLayouts: [
        {
          value: "default",
          label: "Default",
        },
        {
          value: "detailed",
          label: "Detailed",
        },
      ],
      specialtyTypes: {
        oncology: {
          label: "Oncology",
          icon: oncologyIcon,
          background: "green",
        },
        cardiology: {
          label: "Cardiology",
          icon: cardiologyIcon,
          background: "red",
        },
        orthopedics: {
          label: "Orthopedics",
          icon: orthopedicsIcon,
          background: "blue",
        },
      },
      landerActions: [
        {
          label: "Assessment",
          value: "assessment",
        },
        {
          label: "Contact",
          value: "contact",
        },
        {
          label: "Scroll",
          value: "scroll",
        },
      ],
    };
  },
  computed: {
    selectedContent() {
      return this.$store.getters["engage/selectedContent"];
    },
    fields() {
      return {
        media: {
          label: "Image",
          type: "media",
          model: "media",
        },
        super_heading: {
          label: "Super Heading",
          type: "text",
          model: "super_heading",
        },
        heading: {
          label: "Heading",
          type: "editor",
          editorType: "heading",
          model: "heading",
        },
        sub_heading: {
          label: "Sub Heading",
          type: "text",
          model: "sub_heading",
        },
        body: {
          label: "Body",
          type: "editor",
          editorType: "body",
          model: "body",
        },
        conditions: {
          label: "Conditions",
          type: "textarea",
          model: "body",
        },
        message: {
          label: "Message",
          type: "text",
          model: "body",
        },
        call_to_action: {
          label: "Call to Action",
          type: "text",
          model: "call_to_action",
        },
        action: {
          label: "Action Event",
          type: "select",
          model: "action",
          options: this.actions, //inherited from component
        },
        target_id: {
          label: "Action Target",
          type: "select",
          model: "target_id",
          options: this.actionTargets,
          updateOn: "action",
          visible: (updateOn) => {
            return updateOn === "scroll";
          },
        },
        collection_layout: {
          label: "Layout",
          type: "select",
          model: "layout",
          options: this.collectionLayouts,
        },
        collection: {
          label: "Content Offers",
          type: "checkbox",
          model: "collection",
          options: this.offers,
        },
        cards: {
          label: "Cards",
          type: "cards",
          model: "cards",
        },
        card_layout: {
          label: "Layout",
          type: "select",
          model: "layout",
          options: this.cardLayouts,
        },
        hero_layout: {
          label: "Layout",
          type: "select",
          model: "layout",
          options: this.heroLayouts,
        },
        infographic_layout: {
          label: "Layout",
          type: "select",
          model: "layout",
          options: this.infographicLayouts,
        },
        leadin_layout: {
          label: "Layout",
          type: "select",
          model: "layout",
          options: this.leadInLayouts,
        },
        testimonial_layout: {
          label: "Layout",
          type: "select",
          model: "layout",
          options: this.testimonialLayouts,
        },
        url: {
          label: "URL",
          type: "text",
          model: "body",
        },
        list: {
          label: "List",
          type: "list",
          model: "list",
        },
        list_layout: {
          label: "Layout",
          type: "select",
          model: "layout",
          options: this.listLayouts,
        },
        spotlight_layout: {
          label: "Layout",
          type: "select",
          model: "layout",
          options: this.spotlightLayouts,
        },
      };
    },
    contentTypes() {
      return {
        hero: {
          label: "Hero",
          icon: heroIcon,
          fields: [
            this.fields.hero_layout,
            this.fields.media,
            this.fields.heading,
            this.fields.body,
            this.fields.call_to_action,
            this.fields.action,
            this.fields.target_id,
          ],
          views: ["lander", "email"],
        },
        form_hero: {
          label: "Form Hero",
          icon: formHeroIcon,
          fields: [
            this.fields.media,
            this.fields.heading,
            this.fields.sub_heading,
            //this.fields.conditions,
          ],
          views: ["lander"],
        },
        lead_in: {
          label: "Lead In",
          icon: leadInIcon,
          fields: [
            this.fields.leadin_layout,
            this.fields.heading,
            this.fields.body,
            this.fields.call_to_action,
            this.fields.action,
            this.fields.target_id,
          ],
          views: ["lander", "email"],
        },
        video: {
          label: "Video",
          icon: videoIcon,
          fields: [this.fields.media, this.fields.url],
          views: ["lander"],
        },
        left_block: {
          label: "Left Block",
          icon: leftBlockIcon,
          fields: [
            this.fields.media,
            this.fields.heading,
            this.fields.body,
            this.fields.call_to_action,
            this.fields.action,
            this.fields.target_id,
          ],
          views: ["lander", "email"],
        },
        right_block: {
          label: "Right Block",
          icon: rightBlockIcon,
          fields: [
            this.fields.media,
            this.fields.super_heading,
            this.fields.heading,
            this.fields.sub_heading,
            this.fields.body,
            this.fields.call_to_action,
            this.fields.action,
            this.fields.target_id,
          ],
          views: ["lander", "email"],
        },
        spotlight: {
          label: "Spotlight",
          icon: spotlightIcon,
          fields: [
            this.fields.spotlight_layout,
            this.fields.media,
            this.fields.super_heading,
            this.fields.heading,
            this.fields.body,
            this.fields.call_to_action,
            this.fields.action,
            this.fields.target_id,
          ],
          views: ["lander", "email"],
        },
        long_form: {
          label: "Long Form",
          icon: longFormIcon,
          fields: [
            this.fields.media,
            this.fields.body,
            this.fields.call_to_action,
            this.fields.action,
            this.fields.target_id,
          ],
          views: ["lander", "email"],
        },
        testimonial: {
          label: "Testimonial",
          icon: testimonialIcon,
          fields: [
            this.fields.testimonial_layout,
            this.fields.media,
            this.fields.sub_heading,
            this.fields.body,
          ],
          views: ["lander"],
        },
        collection: {
          label: "Collection",
          icon: collectionIcon,
          fields: [
            this.fields.heading,
            this.fields.body,
            this.fields.collection_layout,
            this.fields.collection,
          ],
          views: ["lander", "email"],
        },
        cards: {
          label: "Content Cards",
          icon: cardGroupIcon,
          fields: [
            this.fields.heading,
            this.fields.super_heading,
            this.fields.card_layout,
            this.fields.cards,
            this.fields.call_to_action,
            this.fields.action,
            this.fields.target_id,
          ],
          views: ["lander", "email"],
        },
        list: {
          label: "List",
          icon: listIcon,
          fields: [this.fields.list_layout, this.fields.list],
          views: ["lander"],
        },
        data_visualization: {
          label: "Data Viz",
          icon: dataVisualizationIcon,
          fields: [this.fields.media, this.fields.heading],
          views: ["lander", "email"],
        },
        scheduler: {
          label: "Scheduler",
          icon: schedulerIcon,
          fields: [this.fields.heading, this.fields.body],
          views: ["lander"],
        },
        plain_text: {
          label: "Plain Text",
          icon: leadInIcon,
          fields: [this.fields.message],
          views: ["sms"],
        },
        assessment_results: {
          label: "Assessment Results",
          icon: schedulerIcon,
          fields: [this.fields.heading, this.fields.sub_heading],
          views: ["email"],
        },
        infographic: {
          label: "Infographic",
          icon: infographicIcon,
          fields: [
            this.fields.heading,
            this.fields.sub_heading,
            this.fields.infographic_layout,
            this.fields.cards,
            this.fields.call_to_action,
            this.fields.action,
            this.fields.target_id,
          ],
          views: ["lander"],
        },
        gate: {
          label: "Gate",
          icon: gateIcon,
          fields: [
            this.fields.heading,
            this.fields.body,
            this.fields.call_to_action,
          ],
          views: ["lander"],
        },
      };
    },
    assessments() {
      return this.$store.getters["activate/assessments"];
    },
    audiences() {
      return this.$store.getters["identification/audiences"];
    },
    loading() {
      return this.$store.getters["engage/loading"];
    },
    campaigns() {
      return this.$store.getters["engage/campaigns"];
    },
    campaign() {
      return this.$store.getters["engage/campaign"];
    },
    landers() {
      let views = this.$store.getters["engage/views"];
      return views.filter((view) => {
        return view.type === "lander";
      });
    },
    viewSaving: function () {
      return this.$store.getters["engage/viewSaving"];
    },
    ads() {
      return this.$store.getters["engage/ads"];
    },
    emails() {
      let views = this.$store.getters["engage/views"];
      return views.filter((view) => {
        return view.type === "email";
      });
    },
    emailActions() {
      return this.offers.map((offer) => {
        return {
          label: `Link to ${offer.title}`,
          value: offer.id,
        };
      });
    },
    sms() {
      let views = this.$store.getters["engage/views"];
      return views.filter((view) => {
        return view.type === "sms";
      });
    },
    tags() {
      return this.$store.getters["engage/tags"];
    },
    offers() {
      return this.$store.getters["contentOffers/offers"];
    },
    actionTargets() {
      let list = [];
      if (this?.selectedContent?.components) {
        return this.selectedContent.components.map((item) => {
          return {
            label: `${this.formatComponentName(item.type)}, Component ${
              item.priority + 1
            }`,
            value: item.id,
          };
        });
      }
      return list;
    },
  },
  methods: {
    getColorBySpecialty(specialty) {
      if (typeof this.specialtyTypes[specialty] != "undefined") {
        return this.specialtyTypes[specialty].background;
      }

      return "green";
    },
    formatComponentName(key) {
      let string = key.split("_").join(" ");
      return voca.titleCase(string);
    },
  },
  mounted() {
    if (!this.assessments.length) {
      this.$store.dispatch("activate/getAssessments");
    }

    if (!this.tags.length) {
      this.$store.dispatch("engage/getTags");
    }

    this.$store.dispatch("contentOffers/getOffers", {
      campaign: this.campaign.id,
    });
  },
};
