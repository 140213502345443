var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle-icon",class:{
    'circle-icon--alert': _vm.alert,
    'circle-icon--pinned': _vm.pinned,
    'circle-icon--lg': _vm.lg,
    'circle-icon--xl': _vm.xl,
    'circle-icon--green': _vm.color === 'green',
    'circle-icon--blue': _vm.color === 'blue',
    'circle-icon--red': _vm.color === 'red',
  }},[_c('BaseSVG',{attrs:{"src":require(("@/assets/" + _vm.icon + "-icon.svg"))}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }