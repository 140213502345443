<template>
  <div
    class="pill"
    :class="{ active: isActive, alert, label, clickable, enabled, warning }"
    @click="onClick"
  >
    {{ count }}
  </div>
</template>

<script>
export default {
  name: "Pill",
  props: ["count", "alert", "label", "clickable", "enabled", "warning"],
  computed: {
    isActive() {
      return typeof this.count === "number" && this.count > 0;
    },
  },
  methods: {
    onClick() {
      if (this.clickable) {
        this.$emit("on-click");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.pill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  height: 1.5rem;
  font-size: 0.75rem;
  border-radius: 0.75rem;
  background: $light-blue;
  color: #fff;
  opacity: 0.35;
  padding: 0 0.75rem;

  &.active {
    opacity: 1;
  }

  &.alert {
    opacity: 1;
    background: $red;
  }

  &.label {
    opacity: 1;
    background: $fade-green;
    color: $dark-green;
    text-transform: capitalize;

    &.alert {
      background: $fade-red;
      color: $dark-red;
    }

    &.warning {
      background: $fade-yellow;
      color: $dark-yellow;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.enabled {
    background: $green;
    color: #fff;
  }
}
</style>